import React from "react";
import { Container, Row, Col, Media } from "react-bootstrap";

const Offer = (props, ref) => (
  <div className="offer page" ref={ref}>
    <Container>
      <Row>
        <Col xs={12} md={12}>
          <h2 className="title text-center">Oferta</h2>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={12}>
          <Media>
            <Media.Body className="text">
              <ul>
                <li>diagnoza psychologiczna</li>
                <li>konsultacje i poradnictwo psychologiczne</li>
                <li>poradnictwo dla rodziców</li>
                <li>psychoterapia indywidualna</li>
              </ul>
              <p>
                Pracuję z dorosłymi, dziećmi&nbsp;i&nbsp;młodzieżą. Zajmuję się
                zaburzeniami nastroju, zaburzeniami lękowymi, zaburzeniami
                osobowości, zaburzeniami psychosomatycznymi, zaburzeniami
                odżywiania, problemami&nbsp;w&nbsp;relacjach, trudnościami
                adaptacyjnymi, zaburzeniami ze spektrum autyzmu.
              </p>
              <p>
                Moim celem jest pomoc klientom&nbsp;w&nbsp;trudnych momentach,
                aby na nowo odkryli swoje możliwości, zaczęli odczuwać
                satysfakcję &nbsp;i&nbsp;byli&nbsp;w&nbsp;stanie samodzielnie
                pokonywać napotykane trudności.
              </p>
              <p>
                Spotkania najczęściej odbywają się raz&nbsp;w&nbsp;tygodniu, w
                zależności od zgłaszanego problemu oraz możliwości klienta.
                Sesja trwa 50 minut. Długość trwania terapii jest kwestią
                indywidualną.
              </p>
            </Media.Body>
          </Media>
        </Col>
      </Row>
    </Container>
  </div>
);

export default React.forwardRef(Offer);
