import React, { useState, useEffect } from "react";
import { Parallax } from "react-scroll-parallax";

const ParallaxImage = ({
  className,
  containerClassName,
  src,
  innerPhotoInitialTransform,
  ...rest
}) => {
  const [imgLoaded, setImgLoaded] = useState(false);
  useEffect(() => {
    const img = new Image();
    img.src = process.env.PUBLIC_URL + src + ".jpg";
    // Once image is loaded replace the src of the HTML element
    img.onload = () => setImgLoaded(true);
  }, []);

  return (
    <div className={`paralax_image_container ${containerClassName}`}>
      <Parallax {...rest}>
        <div
          className={`paralax--photo ${className} ${
            !imgLoaded ? "loading" : ""
          }`}
          style={{
            backgroundImage: `url(${
              imgLoaded
                ? process.env.PUBLIC_URL + src + ".jpg"
                : process.env.PUBLIC_URL + src + "_tn.jpg"
            })`,
            ...innerPhotoInitialTransform,
          }}
        />
      </Parallax>
    </div>
  );
};

export default ParallaxImage;
