import React, { useState } from "react";

const initialState = {
  name: "",
  _replyto: "",
  phone: "",
  message: ""
};
const Contact = () => {
  const [values, setValue] = useState(initialState);

  return (
    <form
      id="contact"
      action="https://formspree.io/xzbjovkn"
      method="post"
    >
      <h3>Formularz kontaktowy</h3>
      <h4>Zapraszam do konsultacji ws. umówienia wizyty</h4>
      <fieldset>
        <label for="name">Imię i nazwisko</label>
        <input
          onChange={e => setValue({ ...values, name: e.target.value })}
          value={values.name}
          placeholder="Imię i nazwisko"
          id="name"
          name="name"
          type="text"
          tabIndex="1"
          required
        />
      </fieldset>
      <fieldset>
        <label for="_replyto">Adres email</label>
        <input
          onChange={e => setValue({ ...values, _replyto: e.target.value })}
          value={values._replyto}
          placeholder="email@domain.com"
          type="email"
          tabIndex="2"
          name="_replyto"
          id="_replyto"
          required
        />
      </fieldset>
      <fieldset>
        <label for="phone">Nr telefonu</label>
        <input
          onChange={e => setValue({ ...values, phone: e.target.value })}
          value={values.phone}
          placeholder="xxx-xxx-xxx"
          name="phone"
          id="phone"
          type="tel"
          tabIndex="3"
        />
      </fieldset>
      <fieldset>
        <label for="message">Wiadomość</label>
        <textarea
          onChange={e => setValue({ ...values, message: e.target.value })}
          value={values.message}
          placeholder="Treść wiadomości...."
          tabIndex="4"
          required
          id="message"
          name="message"
        />
      </fieldset>
      <input type="hidden" name="_language" value="pl" />
      <input
        type="hidden"
        name="_next"
        value={window.location.origin + "#thanks"}
      />
      <fieldset>
        <button
          name="submit"
          type="submit"
          tabIndex="5"
          id="contact-submit"
          data-submit="...Sending"
        >
          Wyślij
        </button>
      </fieldset>
    </form>
  );
};

export default Contact;
