import React, { useRef } from "react";
import { ParallaxProvider } from "react-scroll-parallax";

import "../node_modules/bootstrap/dist/css/bootstrap.css";

import Layout from "./Layout/Layout";
import Navbar from "./Layout/Navbar";
import Footer from "./Layout/Footer";

// Components
import ParallaxImage from "./Components/ParallaxImage";
import Cookies from "./Components/Cookies";
import Modal from "./Components/Modal";

// Pages
import Home from "./Pages/Home";
import About from "./Pages/About";
import Psychotherapy from "./Pages/Psychotherapy";
import Offer from "./Pages/Offer";
import Prices from "./Pages/Prices";
import Contact from "./Pages/Contact";

let refs;
const onNavItemClick = componentRef => {
  window.scrollTo({
    top: refs[componentRef].current.offsetTop - 50,
    behavior: "smooth"
  });
};
const App = () => {
  refs = {
    home: useRef(),
    about: useRef(),
    psychotherapy: useRef(),
    offer: useRef(),
    pricing: useRef(),
    contact: useRef()
  };

  return (
    <Layout>
      <Modal />
      <ParallaxProvider>
        <Navbar onNavItemClick={onNavItemClick} />
        <Cookies />
        <Home onArrowDownClick={onNavItemClick} ref={refs.home} />
        <About ref={refs.about} />
        <ParallaxImage
          offsetYMin={15}
          offsetYMax={-15}
          className="photo--1"
          containerClassName="container--1"
          src={"/images/kari-shea-99868-unsplash-min"}
        />
        <Psychotherapy ref={refs.psychotherapy} />
        <ParallaxImage
          offsetYMin={15}
          offsetYMax={-15}
          className="photo--2"
          containerClassName="container--2"
          src={"/images/thought-2123971-min"}
        />
        <Offer ref={refs.offer} />
        <ParallaxImage
          offsetYMin={15}
          offsetYMax={-15}
          className="photo--3"
          containerClassName="container--3"
          src={"/images/writing-828911-min"}
        />
        <Prices ref={refs.pricing} />
        <Contact ref={refs.contact} />
        <Footer onArrowDownClick={onNavItemClick} />
      </ParallaxProvider>
    </Layout>
  );
};

export default App;
