import { Col, Container, Media, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { SRLWrapper } from "simple-react-lightbox";

import debounce from "lodash/debounce";

const desktopScreenText = `
  Jestem absolwentką psychologii na Uniwersytecie  Marii  Skłodowskiej – Curie w Lublinie na dwóch specjalnościach: klinicznej i wychowawczej.
  Ukończyłam także podyplomowe studia kwalifikacyjne „Diagnoza i terapia neuropsychologiczna” na UMCS oraz 4 - letnie szkolenie do uzyskania certyfikatu psychoterapeuty poznawczo - behawioralnego w Centrum Terapeutyczno - Szkoleniowym Tercognitiva w Krakowie - certyfikat nr 949.
  Swoją pracę poddaję obowiązkowej superwizji, jestem członkiem Polskiego Towarzystwa Terapii Poznawczo-Behawioralnej (PTTPB). Pracuję zarówno z dziećmi, jak i z dorosłymi.
  Stale podnoszę swoje kompetencje uczestnicząc w różnego rodzaju konferencjach, kursach i szkoleniach, szczególnie z zakresu ACT, DBT, terapii schematu i mindfulness.
`;
const mobileScreenText = desktopScreenText.replace(/&nbsp;/g, " ");

const mobileTextMarkup = { __html: mobileScreenText };
const aboutTextMarkup = { __html: desktopScreenText };

const handleResize = (setText) => () => {
  const lastWidth = window.innerWidth;

  if (lastWidth < 500) setText(mobileTextMarkup);
  else setText(aboutTextMarkup);
};

const About = (props, ref) => {
  const [imgLoaded, setImgLoaded] = useState(false);
  const [text, setText] = useState(aboutTextMarkup);
  useEffect(() => {
    const img = new Image();
    img.src = process.env.PUBLIC_URL + "/images/iwona_piesko.jpg";
    // Once image is loaded replace the src of the HTML element
    img.onload = () => setImgLoaded(true);
    window.addEventListener("resize", debounce(handleResize(setText), 250));

    return () =>
      window.removeEventListener(
        "resize",
        debounce(handleResize(setText), 250)
      );
  }, []);

  return (
    <div className="about page" ref={ref}>
      <Container>
        <Row>
          <Col xs={12} md={12}>
            <h2 className="title text-center">O mnie</h2>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12}>
            <Media>
              <div className="about--photo">
                <img
                  width={200}
                  src={`${process.env.PUBLIC_URL}/images/iwona_piesko${
                    imgLoaded ? "" : "_tn"
                  }.jpg`}
                  className={`about--photo ${!imgLoaded ? "loading" : ""}`}
                  alt="Iwona Piesko zdjęcie"
                />
              </div>
              <Media.Body className="text">
                <p dangerouslySetInnerHTML={text} />
                <div className="signature text-right">mgr Iwona Piesko</div>

                <SRLWrapper
                  options={{
                    settings: {
                      disablePanzoom: true
                    },
                    buttons: {
                      showDownloadButton: false,
                      showAutoplayButton: false,
                      showFullscreenButton: false,
                      showThumbnailsButton: false
                    },
                  }}
                >
                  <div className="certs">
                    <img
                      width={300}
                      src={`${process.env.PUBLIC_URL}/images/Certyfikat Psychoterapeuty poznawczo behawioralnego - Iwona Piesko.jpg`}
                      className={`about--photo ${!imgLoaded ? "loading" : ""}`}
                      alt="Certyfikat Psychoterapeuty Poznawczo-Behawioralnego - Iwona Piesko"
                    />
                    <img
                      width={300}
                      src={`${process.env.PUBLIC_URL}/images/Świadectwo Ukończenia Studiów - Iwona Piesko.jpg`}
                      className={`about--photo ${!imgLoaded ? "loading" : ""}`}
                      alt="Świadectwo Ukończenia Studiów - Iwona Piesko"
                    />
                  </div>
                </SRLWrapper>
              </Media.Body>
            </Media>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default React.forwardRef(About);
