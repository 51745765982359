import React from "react";

const currentYear = new Date().getFullYear();

const Footer = ({ onArrowDownClick }) => (
  <div id="responsive-footer-nav" className="footer">
    <a
      href="#home"
      title="Przewin do góry"
      onClick={() => onArrowDownClick("home")}
    >
      {currentYear} © Gabinet Psychoterapii - Iwona Piesko
    </a>
  </div>
);

export default React.memo(Footer);
