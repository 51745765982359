import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Parallax } from "react-scroll-parallax";
import ContactDetails from "../Components/ContactDetails";
import ContactForm from "../Components/ContactForm";

const Contact = ({ className }, ref) => {
  const [imgLoaded, setImgLoaded] = useState(false);
  useEffect(() => {
    const img = new Image();
    img.src =
      process.env.PUBLIC_URL +
      "/images/chris-barbalis-595272-unsplash-min.jpg";
    // Once image is loaded replace the src of the HTML element
    img.onload = () => setImgLoaded(true);
  }, []);

  return (
    <div
      className={`contact--container ${className}`}
      style={{ overflow: "hidden" }}
      ref={ref}
    >
      <Parallax offsetYMin={10} offsetYMax={-10}>
        <div
          className="contact page"
          style={{
            backgroundImage: `url(${
              process.env.PUBLIC_URL
            }/images/chris-barbalis-595272-unsplash-min${
              imgLoaded ? "" : "_tn"
            }.jpg)`,
            backgroundPosition: "center"
          }}
        >
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <ContactForm />
              </Col>
              <Col xs={12} md={6}>
                <ContactDetails />
              </Col>
            </Row>
          </Container>
        </div>
      </Parallax>
    </div>
  );
};

export default React.forwardRef(Contact);
