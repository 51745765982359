import React from "react";
import { Row, Col } from "react-bootstrap";

const ContactDetails = props => (
  <div className="contact--details">
    <h3>Dane kontaktowe</h3>
    <div className="details_container">
      <Row>
        <Col xs={3} md={3} className="text-right">
          <div className="column">Telefon:</div>
          <div className="column">Adres:</div>
        </Col>

        <Col xs={9} md={9} className="details--values">
          <div>
            <a className="phone-number" href="tel:+48730093311">
              +48 730 093 311
            </a>
          </div>
          <div>ul. Hoffmanowej 19</div>
          <div>Rzeszów</div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={12}>
          <div className="mapouter">
            <div className="gmap_canvas">
              <iframe
                title="google_maps"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2562.7407505230285!2d21.98986211571636!3d50.03495347942017!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x473cfb8c4fc77093%3A0x5ad48c06f3941542!2sIwona%20Piesko%20Psycholog!5e0!3m2!1sen!2spl!4v1624730973858!5m2!1sen!2spl"
                width="100%"
                height="360"
                frameBorder="0"
                style={{border:0}}
                allowFullScreen
                marginHeight={0}
                marginWidth={0}
              />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  </div>
);

export default ContactDetails;
