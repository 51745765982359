import React, { useState, useEffect } from "react";
import { default as BootstrapNavbar } from "react-bootstrap/lib/Navbar";
import NavLink from "react-bootstrap/lib/NavLink";
import Nav from "react-bootstrap/lib/Nav";
import debounce from "lodash/debounce";

const handleResize = setTitle => () => {
  const lastWidth = window.innerWidth;

  window.requestAnimationFrame(() => {
    if (lastWidth < 460) {
      setTitle(
        <>
          <div className="text-center">Psychoterapia</div>
          <div>poznawczo-behawioralna</div>
          <div className="text-center">mgr Iwona Piesko</div>
        </>
      );
    } else if (lastWidth < 650) {
      setTitle(
        <>
          <div className="text-center">
            Psychoterapia poznawczo-behawioralna
          </div>
          <div className="text-center">mgr Iwona Piesko</div>
        </>
      );
    } else if (lastWidth > 1250 || lastWidth <= 980)
      setTitle("Psychoterapia poznawczo-behawioralna - mgr Iwona Piesko");
    else if (lastWidth > 980) {
      setTitle(
        <>
          <div className="text-center">
            Psychoterapia poznawczo-behawioralna
          </div>
          <div className="text-center">mgr Iwona Piesko</div>
        </>
      );
    }
  });
};

const Navbar = ({ onNavItemClick }) => {
  const [title, setTitle] = useState(
    "Psychoterapia poznawczo-behawioralna - mgr Iwona Piesko"
  );
  useEffect(() => {
    window.addEventListener("resize", debounce(handleResize(setTitle), 250));

    return () =>
      window.removeEventListener(
        "resize",
        debounce(handleResize(setTitle), 250)
      );
  }, []);

  return (
    <BootstrapNavbar
      collapseOnSelect
      expand="lg"
      bg="light"
      variant="light"
      fixed="top"
    >
      <BootstrapNavbar.Brand
        onClick={() => onNavItemClick("home")}
        href="#home"
        className="page-title"
      >
        {title}
      </BootstrapNavbar.Brand>
      <BootstrapNavbar.Toggle aria-controls="responsive-navbar-nav" />
      <BootstrapNavbar.Collapse id="responsive-navbar-nav">
        <Nav className="ml-auto">
          <NavLink
            className="nav-item"
            onClick={() => onNavItemClick("home")}
            href="#home"
          >
            Home
          </NavLink>
          <NavLink
            className="nav-item"
            onClick={() => onNavItemClick("about")}
            href="#about"
          >
            O mnie
          </NavLink>
          <NavLink
            className="nav-item"
            onClick={() => onNavItemClick("psychotherapy")}
            href="#psychotherapy"
          >
            Psychoterapia
          </NavLink>
          <NavLink
            className="nav-item"
            onClick={() => onNavItemClick("offer")}
            href="#offer"
          >
            Oferta
          </NavLink>
          <NavLink
            className="nav-item"
            onClick={() => onNavItemClick("pricing")}
            href="#pricing"
          >
            Cennik
          </NavLink>
          <NavLink
            className="nav-item"
            onClick={() => onNavItemClick("contact")}
            href="#contact"
          >
            Kontakt
          </NavLink>
          {/*
            <span className="separator" />
            <NavLink className="nav-item" href="blog">
              Blog
            </NavLink>
          */}
        </Nav>
      </BootstrapNavbar.Collapse>
    </BootstrapNavbar>
  );
};

export default Navbar;
