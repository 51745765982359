import React from "react";
import { Container, Row, Col, Media } from "react-bootstrap";

const Psychotherapy = (props, ref) => (
  <div className="psychotherapy page" ref={ref}>
    <Container>
      <Row>
        <Col xs={12} md={12}>
          <h2 className="title text-center">
            Psychoterapia poznawczo &nbsp;–&nbsp; behawioralna
          </h2>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={12}>
          <Media>
            <Media.Body className="text">
              <p>
                Terapia poznawczo&nbsp;i&nbsp;behawioralna, inaczej CBT
                (Cognitive Behavioral Therapy) jest jedną z najbardziej
                udokumentowanych badaniami
                naukowymi&nbsp;i&nbsp;najskuteczniejszą formą terapii.
                Udowodniono jej zastosowanie&nbsp;w&nbsp;terapii uzależnień,
                zaburzeń lękowych&nbsp;i&nbsp;depresyjnych, zaburzeń odżywiania,
                zaburzeń osobowości oraz wielu innych trudnościach, z którymi
                zgłaszają się klienci. Zakłada nierozerwalny związek myśli,
                emocji&nbsp;i&nbsp;zachowania, które nieustannie
                wzajemnie&nbsp;na&nbsp;siebie oddziałują. CBT opiera się
                &nbsp;na&nbsp;pracy „tu&nbsp;i&nbsp;teraz” oraz poszukuje
                interpretacji, jakie człowiek nadaje zdarzeniom. Istotne jest
                także wykształcenie nowych strategii radzenia sobie, które
                zastąpią nieprawidłowe dotąd wzorce. W terapii
                poznawczo&nbsp;-&nbsp;behawioralnej to klient jest ekspertem, a
                terapeuta jedynie pomaga mu&nbsp;w&nbsp;znalezieniu właściwych
                sposobów zmiany.
              </p>
            </Media.Body>
          </Media>
        </Col>
      </Row>
    </Container>
  </div>
);

export default React.forwardRef(Psychotherapy);
