import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";

const Cookies = () => {
  const [showThanksModal, setModalVisibility] = useState(false);
  useEffect(() => {
    if (window.location.href.includes("#thanks")) setModalVisibility(true);
  }, []);

  return (
    <Modal show={showThanksModal} onHide={() => setModalVisibility(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Wiadomość została wysłana</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Dziękujemy za skorzystanie z formularza kontaktowego. Odpowiemy na
          Państwa pytania tak szybko jak to możliwe.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => setModalVisibility(false)}>Zamknij</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default React.memo(Cookies);
