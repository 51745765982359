import React, { Component } from "react";
import CookieBanner from "react-cookie-banner";
import cookies from "browser-cookies";
import debounce from "lodash/debounce";

const styles = {
  banner: {
    fontFamily: "Source Sans Pro",
    height: 50,
    background: `rgba(52, 64, 81, 0.88) url(${
      process.env.PUBLIC_URL
    }/images/cookie-min.png) 20px 50% no-repeat`,
    backgroundSize: "30px 30px",
    backgroundColor: "",
    fontSize: "15px",
    fontWeight: 600
  },
  button: {
    border: "1px solid white",
    borderRadius: 4,
    width: 66,
    height: 32,
    lineHeight: "32px",
    background: "transparent",
    color: "white",
    fontSize: "14px",
    fontWeight: 600,
    opacity: 1,
    right: 20,
    marginTop: -18
  },
  message: {
    display: "block",
    padding: "9px 67px",
    lineHeight: 1.3,
    textAlign: "left",
    marginRight: 50,
    color: "white"
  },
  link: {
    textDecoration: "none",
    fontWeight: "bold"
  }
};

const message =
  "Nasza strona internetowa używa plików cookies (tzw. ciasteczka) w celach funkcjonalnych. Kontynuując przeglądanie zgadzasz się na ich wykorzystanie.";
let lastScrollY = 0;

class Cookies extends Component {
  constructor(props) {
    super(props);

    this.state = {
      accepted: cookies.get("accepts-cookies"),
      removeFromDom: cookies.get("accepts-cookies")
    };

    this.componentDidMount = this.componentDidMount.bind(this);
    this.componentWillUnmount = this.componentWillUnmount.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", debounce(this.handleScroll, 250));
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", debounce(this.handleScroll, 250));
  }

  handleScroll = () => {
    lastScrollY = window.scrollY;

    window.requestAnimationFrame(() => {
      if (lastScrollY > 100) {
        this.setState({ accepted: true });
        setTimeout(() => {
          cookies.set("accepts-cookies", "true");
        }, 800);
      }
    });
  };

  render() {
    return this.state.removeFromDom ? null : (
      <CookieBanner
        className={this.state.accepted ? "hide" : ""}
        styles={styles}
        message={message}
        dismissOnScrollThreshold={1500}
        dismissOnScroll={true}
        dismissOnClick={true}
        buttonMessage="Zamknij"
        onAccept={() => this.setState({ accepted: true })}
      />
    );
  }
}

export default Cookies;
