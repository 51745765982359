import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Home = ({ onArrowDownClick }, ref) => {
  const [imgLoaded, setImgLoaded] = useState(false);
  useEffect(() => {
    const img = new Image();
    img.src = process.env.PUBLIC_URL + "/images/agriculture-1845835-min.jpg";
    // Once image is loaded replace the src of the HTML element
    img.onload = () => setImgLoaded(true);
  }, []);

  return (
    <div className="home" ref={ref}>
      <div
        className={`home_image-container--image ${!imgLoaded ? "loading" : ""}`}
        style={{
          backgroundImage: `url(${
            process.env.PUBLIC_URL
          }/images/agriculture-1845835-min${imgLoaded ? "" : "_tn"}.jpg)`
        }}
      />
      <div
        className="home--arrow-down"
        onClick={() => onArrowDownClick("about")}
      >
        <FontAwesomeIcon size="3x" icon="angle-down" />
      </div>
      <div className="home--quote">
        <section className="effect effect-c">
          <h1 className="effect__heading">
            “Bądź zmianą, którą pragniesz ujrzeć&nbsp;w&nbsp;świecie.”{" "}
          </h1>
          <h2 className="effect__subheading">Gandhi</h2>
        </section>
      </div>
    </div>
  );
};

export default React.forwardRef(Home);
